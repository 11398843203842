import IncomeCard from './IncomeCard';
import GrowthOverview from './GrownCard';
import RecentOrderClass from './RecentOrderCard';
import UserCard from './UserCard';
import TransactionCard from './TransactionCard';
import { Container, Row, Col } from 'reactstrap';
import React from 'react';
import { AlertCircle, CreditCard, DollarSign, File, FileMinus, Home, ShoppingBag, Users, UserX } from 'react-feather';


const Dashboard = () => {
    return (
        <Container fluid={true} className="dashboard-default-sec">
            <Row>
                <Col xl="12" className="box-col-12 des-xl-100">
                    <Row>
                        
                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<Users />} amount='8,50' title='Total Registered User' percent="95.54%" />
                        </Col>
                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<DollarSign />} amount='2,03,59' title='Total Registered Accounts' percent="90.54%" />
                        </Col>

                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<CreditCard />} amount='2,03,59' title='Total Registered Cards' percent="90.54%" />
                        </Col>
   
                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<AlertCircle />} amount='8,50' title='Total Pending Reports' percent="95.54%" />
                        </Col>
                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<File />} amount='2,59' title='Total Reports' percent="90.54%" />
                        </Col>

                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<ShoppingBag />} amount='2,59' title='Total Recovery Request' percent="90.54%" />
                        </Col>

                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<UserX />} amount='2,59' title='Total Freeze Request' percent="90.54%" />
                        </Col>

                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<FileMinus />} amount='2,59' title='Total Bank Frauds' percent="90.54%" />
                        </Col>

                        <Col xl="4" md="4" sm="6" className="box-col-3 des-xl-25 rate-sec">
                            <IncomeCard iconClass={<DollarSign />} amount='2,59' title='Total Financial Fraud' percent="90.54%" />
                        </Col>
                    </Row>
                </Col >
                
                <Col xl="8" className="box-col-12 des-xl-100">
                    <Row>
                        <Col xl="12" className="col-50 box-col-6 des-xl-50">
                            <GrowthOverview />
                        </Col>
                        <Col xl="12" className="recent-order-sec">
                            <RecentOrderClass />
                        </Col>
                    </Row>
                </Col>
                <Col xl="4" className="box-col-12 des-xl-100">
                    <Row>
                        <Col xl="12" className="box-col-6 des-xl-50">
                            <UserCard />
                        </Col>
                        <Col xl="12" className="box-col-6 des-xl-50">
                            <TransactionCard />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;